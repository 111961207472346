const nwdSlide = (() => {

  let NwdSwiper = document.querySelectorAll('.nwdSwiper')
  if (NwdSwiper.length > 0) {

    const swiper = new Swiper(".swiper", {
      loop: true,
      speed: 1000,
      slidesPerView: 1,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      // effect: 'fade',
    });

  }

})();
export default nwdSlide;