const topGallery = (() => {
  let pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {

    let sW = document.documentElement.clientWidth;
    if (sW < 751) {

      let jsGalleryList = document.querySelectorAll('.js-gallery-list');

      for (let i = 0; i < jsGalleryList.length; i++) {
        gsap.to(jsGalleryList[i], {
          scrollTrigger: {
            trigger: jsGalleryList[i],
            // markers: true,
            start: `top 40%+=${sW / 2}`,
            end: `end 40%-=${sW / 2}`,
            onEnter: () => jsGalleryList[i].classList.add('act'),
            onLeave: () => jsGalleryList[i].classList.remove('act'),
            onEnterBack: () => jsGalleryList[i].classList.add('act'),
            onLeaveBack: () => jsGalleryList[i].classList.remove('act'),
          }
        });
      }

    }

  }
})();
export default topGallery;