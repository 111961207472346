import { scrollControlFunc } from './index';

const comLoad = () => {

  let jsTopLoadWrap = document.getElementById('jsTopLoadWrap');
  let jsFirstWrap = document.getElementById('jsFirstWrap');

  jsTopLoadWrap.classList.add('act');

  setTimeout(() => {
    jsTopLoadWrap.classList.add('none');
  }, 1300)


  gsap.set(jsFirstWrap, {
    y: -40,
    opacity: 0,
  });
  gsap.to(jsFirstWrap, {
    y: 0,
    opacity: 1,
    duration: 0.75,
  }, 2.3);

  setTimeout(() => {
    scrollControlFunc.returnScroll();
  }, 2300)


};
export default comLoad;