const comHeader = (() => {

  let jsHeader = document.getElementById('jsHeader');
  let jsNavPc = document.getElementById('jsNavPc');
  let navBtn = document.getElementById('navBtn');
  let navBtnSpan = navBtn.querySelectorAll('span');

  const colorOn = () => {
    jsHeader.classList.add('color-on')
    jsNavPc.classList.add('color-on')
    for(let i = 0; i < navBtnSpan.length; i++){
      navBtnSpan[i].classList.add('color-on')
    }
  }
  const colorOff = () => {
    jsHeader.classList.remove('color-on')
    jsNavPc.classList.remove('color-on')
    for(let i = 0; i < navBtnSpan.length; i++){
      navBtnSpan[i].classList.remove('color-on')
    }
  }

  gsap.to(jsHeader, {
    // opacity: 1,
    scrollTrigger: {
      trigger: 'main',
      start: '1% 1%',
      // markers: true,
      onEnter: () => colorOn(),
      onLeaveBack: () => colorOff(),
    }
  });


})();
export default comHeader;