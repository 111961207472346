const gallerySlide = (() => {

  let pageGallery = (document.getElementById("pageGallery") != null);
  if (pageGallery) {

    const swiper = new Swiper(".swiper", {
      autoplay: {
        delay: 5000,
        // disableOnInteraction: false,
      },
      loop: true,
      speed: 1000,
      slidesPerView: 1,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });

  }

})();
export default gallerySlide;