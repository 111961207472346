const nwdNavCategory = (() => {
  let pageNwd = (document.getElementById("pageNwd") != null);
  if (pageNwd) {

    let pageNwd = document.getElementById('pageNwd');
    if (pageNwd.classList.contains('tounbldg') == true) {

      let sW = document.documentElement.clientWidth;
      let jsNwdBtn = document.getElementById('jsNwdBtn');
      let startPoint = 200 * sW / 1000

      const multiplyOn = () => {
        jsNwdBtn.classList.add('multiply')
      }
      const multiplyOff = () => {
        jsNwdBtn.classList.remove('multiply')
      }

      gsap.to(jsNwdBtn, {
        // opacity: 1,
        scrollTrigger: {
          trigger: '#jsSecMap',
          start: `top ${startPoint}px`,
          // markers: true,
          onEnter: () => multiplyOn(),
          onLeaveBack: () => multiplyOff(),
        }
      });
    }

  }
})();
export default nwdNavCategory;