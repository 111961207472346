const topGalleryMore = (() => {
  let pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {

    let sW = document.documentElement.clientWidth;
    // if (sW < 751) {


    //   let jsFlexbox = document.getElementById("jsFlexbox");
    //   let jsMoreBtn = document.getElementById("jsMoreBtn");
    //   let list = document.querySelectorAll('.js-gallery-list');
    //   let listH = sW
    //   let maxList = listH * list.length
    //   // console.log(list)
    //   // console.log(maxList)

    //   const listAdd = () => {
       
    //     let jsFlexboxH = jsFlexbox.clientHeight;
    //     let rest = maxList - jsFlexboxH
    //     // console.log(rest)

    //     if( listH * 6 > rest ){
    //       let num = rest / listH
    //       jsFlexbox.style.height = jsFlexboxH + listH * num + 'px';
    //     }else{
    //       jsFlexbox.style.height = jsFlexboxH + listH * 6 + 'px';
    //     }

    //     jsFlexboxH = jsFlexbox.clientHeight;

    //     if (maxList <= jsFlexboxH) {
    //       jsMoreBtn.style.display = 'none';
    //     }
    //     // console.log(maxList);
    //     // console.log(jsFlexbox);
    //   }

    //   jsMoreBtn.addEventListener('click', () => {
    //     listAdd()
    //   })
    // }

  }
})();
export default topGalleryMore;