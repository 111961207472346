import {scrollControlFunc, windowResize,comLoad} from './index';

const loadFunc = (() => {
  scrollControlFunc.noScroll();
  windowResize();

  const startFunc = () => {    
    comLoad()
  }

  setTimeout(startFunc, 300)

})();

export default loadFunc;