const pressMore = (() => {
  let pagePress = (document.getElementById("pagePress") != null);
  if (pagePress) {


    let sW = document.documentElement.clientWidth;
    let lists = document.querySelectorAll('.js-gallery-list');
    let jsArchiveBtn = document.getElementById("jsArchiveBtn");
    let listsLength = lists.length;
    let addVal
    if(sW > 750){
      addVal = 12
    }else{
      addVal =8
    }
    // console.log(lists)
    // console.log(maxList)

    if (jsArchiveBtn) {
      document.addEventListener('DOMContentLoaded', () => {
        for (let i = 0; i < listsLength; i++) {
          lists[i].classList.add('none');
        }
        for (let i = 0; i < addVal; i++) {
          lists[i].classList.add('act');
        }
      });
    }

    
    const listAdd = () => {
      let actsList = document.querySelectorAll(".js-gallery-list.act");
      for (let i = actsList.length; i < actsList.length + addVal; i++) {

        if (listsLength === i) {
          jsArchiveBtn.style.display = 'none';
          break;
        }
        lists[i].classList.add('act');
      }
    }


    jsArchiveBtn.addEventListener('click', () => {
      listAdd()
    })


  }
})();
export default pressMore;