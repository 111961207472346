const comScrollTop = (() => {

  let jsScrollTop = document.getElementById('jsScrollTop');

  const actOn = () => {
    jsScrollTop.classList.add('act')
  }
  const actOff = () => {
    jsScrollTop.classList.remove('act')
  }

  gsap.to(jsScrollTop, {
    // opacity: 1,
    scrollTrigger: {
      trigger: 'main',
      start: '1% 1%',
      // markers: true,
      onEnter: () => actOn(),
      onLeaveBack: () => actOff(),
    }
  });


})();
export default comScrollTop;